import './src/assets/css/bootstrap.min.css';
import './src/assets/css/animate.min.css';
import './src/assets/css/boxicons.min.css';
import './src/assets/css/flaticon.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-modal-video/css/modal-video.min.css';
import 'react-18-image-lightbox/style.css';
import 'react-tabs/style/react-tabs.css';
// import '../../../../node_modules/aos/dist/aos.css';
import 'aos/dist/aos.css'; // Corrected path for aos.css
import 'swiper/css';
import 'swiper/css/bundle';
// import './i18n';
// gatsby-browser.js
import 'flag-icons/css/flag-icons.min.css';

// Global Style
import './src/assets/css/style.css';
import './src/assets/css/responsive.css';
import './src/assets/css/dark.css';


import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

export const wrapPageElement = ({ element, props }) => {
    return (
        <I18nextProvider i18n={i18n}>
            {element}
        </I18nextProvider>
    );
};